@use 'src/styles/mixins';
@use 'src/styles/shadows';
@use 'src/styles/colours';

.radioButton {
  @include mixins.button;
  text-align: center;

  &.checked {
    background: var(--secondary);
    color: var(--on-secondary);
  }

  input {
    @include mixins.visually-hidden;
  }
}
