@use '../../styles/gaps';
@use '../../styles/screenSize';

.root {
  padding: 1rem 1rem 1rem gaps.$large;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 2110px;

  gap: gaps.$medium;

  @include screenSize.desktop {
    flex-direction: row;
  }
}
