@use '../../styles/screenSize';
@use '../../styles/mixins';
@use '../../styles/gaps';

.root {
  @include mixins.paper;
  border: none;

  padding: 1rem;

  display: grid;
  grid-auto-flow: row;
  grid-gap: gaps.$medium;
}
