@use 'src/styles/gaps';

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: gaps.$small;
}

.radioGroup {
  display: grid;
  grid-auto-flow: row;
  grid-gap: gaps.$small;
}
