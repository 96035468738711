.root {
  border: none;

  &:disabled {
    &:hover {
      background-color: var(--primary);
      color: var(--on-primary);
    }
  }
}
