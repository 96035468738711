@use 'src/styles/mixins';
@use 'src/styles/shadows';

.root {
  @include mixins.paper;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  box-shadow: shadows.$low;
}

.label {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.numberInput {
  width: 20%;
}
