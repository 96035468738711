@use 'src/styles/screenSize';

.themeBoss {
  --secondary: #3c3744;
  --secondary-light: #484252;
  --secondary-lighter: #554e60;
  --on-primary: #3c3744;
  --on-background: #3c3744;

  &.blue {
    --primary: #a9fff7;
    --background: #508991;
    --on-secondary: #a9fff7;
  }

  &.salmon {
    --primary: #ffa9a3;
    --background: #f87575;
    --on-secondary: #ffa9a3;
  }

  &.pink {
    --primary: #fdcff3;
    --background: #c297b8;
    --on-secondary: #fdcff3;
  }

  &.honeycomb {
    --primary: #f2dc5d;
    --background: #f2a359;
    --on-secondary: #f2dc5d;
  }

  &.arctic {
    --primary: #b8d8d8;
    --background: #7a9e9f;
    --on-secondary: #b8d8d8;
  }

  &.gameboyGreen {
    --primary: #85ffc7;
    --background: #297373;
    --on-secondary: #85ffc7;
  }

  &.greyScale {
    --primary: #fdfdfd;
    --background: #b4b4b4;
    --on-secondary: #fdfdfd;
  }
}

.root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: var(--on-primary);
  background: var(--background);

  @include screenSize.tallScreen {
    align-items: center;
  }
}
