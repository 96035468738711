@use '../../styles/colours';

.root {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--on-primary);
}

.increment {
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.input {
  border: none;
  text-align: center;
}
