@mixin desktop {
  @media (min-width: 875px) {
    @content;
  }
}

@mixin tallScreen {
  @media (min-height: 1200px) {
    @content;
  }
}
