@use 'src/styles/gaps';
@use 'src/styles/colours';

$off: var(--primary);
$darkenAmount: 15%;

.root {
  padding: gaps.$medium;
  margin: 0;
  background: $off;

  box-shadow: none;

  &:not(.switching) {
    transition: background-color 0ms; // TODO: How to make this not effect the actual transition..
  }

  &.first {
    border-top-width: 2px;
  }

  &.last {
    border-bottom-width: 2px;
  }

  &:first-child {
    border-left-width: 2px;
  }
  &:last-child {
    border-right-width: 2px;
  }

  &.active {
    background: var(--secondary);
  }

  &.playingNow {
    &:not(.active) {
      background: var(--background);
    }
    &::after {
      content: '';
      position: absolute;
      background: var(--background);
      width: gaps.$small;

      height: gaps.$small;
      border-radius: 50%;
      &.switching {
        transition: background-color 200ms;
      }
    }
  }
}
