@use 'src/styles/shadows';
@use 'src/styles/colours';

@mixin paper() {
  padding: 0.5rem;
  border: 1px solid var(--on-primary);
  background: var(--primary);
  box-shadow: shadows.$medium;
}

@mixin visually-hidden {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

@mixin button {
  @include paper;
  box-shadow: shadows.$low;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--on-primary);

  &:hover {
    background: var(--secondary-lighter);
    color: var(--on-secondary);
  }

  &:active {
    background: var(--secondary-light);
    color: var(--on-secondary);
  }
}
