@use 'src/styles/screenSize';
@use 'src/styles/colours';
@use 'src/styles/mixins';
@use 'src/styles/timing';

* {
  transition: background-color timing.$short, color timing.$short;
}

button {
  @include mixins.button;
}

body {
  background: var(--background);
}

input {
  background: var(--on-secondary);
  color: var(--secondary);
  border: solid 1px var(--secondary);
  text-align: center;
}

input[type='range'] {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
}

input[type='range']::-moz-range-track {
  background: var(--secondary);
  height: 2px;
  border-radius: 2px;
}

input[type='range']::-webkit-slider-runnable-track {
  background: var(--secondary);
  height: 2px;
  border-radius: 2px;
}

input[type='range']::-moz-range-thumb {
  background-color: var(--secondary);
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-top: -5px;
  height: 12px;
  width: 12px;
  border-radius: 8px;
}

input[type='range']::-webkit-slider-thumb {
  background-color: var(--secondary);
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin-top: -5px;
  height: 12px;
  width: 12px;
  border-radius: 8px;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
